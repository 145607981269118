import { Box, CssBaseline, Grid, makeStyles } from "@material-ui/core";
import Router from "./Router";
import { Header } from "./header/Header";

export default function Layout() {
  const classes = useStyles();
  return (
  <div>
    <CssBaseline />
    <Header />
    <Box id="content" height="calc(100vh - 68)" style={{height:"calc(100vh - 68px)", display:"block", overflow:"auto"}}>
      <main>
        <Router />
        <Grid container className={classes.lizFooter}>
          <img
            src="/images/menu-footer.png"
            alt="liz, powered by thinktum"
            title="liz, powered by thinktum"
            height={18}
            data-testid={`footer-img`}
          />
      </Grid>
      </main>
    </Box>
  </div>

  );
}

const useStyles = makeStyles((theme) => ({
  lizFooter: {
    justifyContent: "center",
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(2),
    },
  },
}));
