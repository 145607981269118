import { Route, Routes } from "react-router-dom";
import { ExtensionPoint } from "@thinktuminc/core-ui-shared-react";
import { ErrorBoundary } from "@thinktuminc/react-components";
import { HomePage } from "./HomePage";
import { AlternateErrorContent } from "./ErrorContent";

function Router(){
    return <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<ErrorBoundary content={<AlternateErrorContent />}>
            <ExtensionPoint extensionPointId="routes" style={{width:"100%"}} />
        </ErrorBoundary>} />
    </Routes>
}

export default Router;