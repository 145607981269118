import {  Grid } from "@material-ui/core";
import { LoginContext, Endpoints } from "@thinktuminc/core-ui-shared-react";
import { useNavigate } from "react-router-dom";
import { commonStyles } from "../styles/CommonStyle";

import * as React from "react";
import { AppConfigContext } from "../Config";
import { BrowserLogger } from "@thinktuminc/browser-logging";
import { LoadingIcon } from "@thinktuminc/react-components";

const logger = new BrowserLogger("AuthAcceptPage");

export function LoginPage() {
    const loginContext = React.useContext(LoginContext);
    const config = React.useContext(AppConfigContext.ReactContext);
    const [loggedIn, setLoggedIn] = React.useState<boolean | null>(null);
    const navigate = useNavigate();
    const commonClass = commonStyles();


    const getLoggedIn = () => {
        logger.silly("Checking if logged in against ", loginContext)
        const login:boolean = loginContext.login.state === "LOGGED_IN";
        setLoggedIn(login);
    };

    const checkLoggedIn = () => {
        if (loggedIn === true){
            logger.debug("Is logged in")
            navigate("/");
        } else if (loggedIn === false) {
            if( !config ){
                throw new Error("Not logged in and not configured");
            }
            logger.debug("Not logged in, redirecting to authorize url")
            window.location.assign(config.authorizeUrl + Endpoints.AUTHORIZE);
        }
    };

    const initialize = async () => {
        getLoggedIn();
    }

    React.useEffect(() => {
        initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        logger.debug("Logged in or config changed", config);
        if(config?.authorizeUrl){
            checkLoggedIn();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedIn, config]);


    return (
        <Grid style={{backgroundColor: "white"}}>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    className={commonClass.centreGrid}
                > 
                        <LoadingIcon />
                </Grid>  
        </Grid>
    );
}
