import React from 'react';
import { Endpoints, ExtensionPoint, LoginContext } from '@thinktuminc/core-ui-shared-react';
import MenuIcon from '@material-ui/icons/Menu';
import { Box, ButtonMasterUI, ErrorBoundary, Menu, Typography, useMediaQuery, useTheme, GradientMenuButton, MenuContext } from '@thinktuminc/react-components';
import { AppConfigContext } from '../../Config';
import { useNavigate } from 'react-router-dom';

function MenuContents({ isMobile }: { isMobile: boolean }): JSX.Element {
  const menuContext = React.useContext(MenuContext)
  const loginContext = React.useContext(LoginContext);
  const navigate = useNavigate();
  const config = React.useContext(AppConfigContext.ReactContext);
  const logout = React.useCallback(async () => {
    loginContext.setLogin({
      ...loginContext,
      login: {
        state: "LOGGING_OUT"
      }
    });

    if (!config) {
      throw new Error("Logout but authorize not configured");
    }
    window.location.assign(config.authorizeUrl + Endpoints.ENDSESSION);
  }, [config, loginContext]);

  const handleNavigateQuickQuoteMobile = () => {
    // eslint-disable-next-line
    navigate("/quick-quote");
    menuContext.hide();
  };

  return <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">

    <Box>
      {isMobile && <Box maxWidth={isMobile ? "100%" : "20em"} overflow={"hidden"} textOverflow="ellipsis" paddingBottom={4}>
        <Typography variant="h4" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{loginContext.login.state === "LOGGED_IN" && loginContext.login.Details.name}</Typography>
      </Box>
      }
      {isMobile && (
        <Box paddingBottom="24px">
          <GradientMenuButton
            text={{ en: "Quick quote" }}
            onClick={handleNavigateQuickQuoteMobile}
          />
        </Box>
      )}
      <ErrorBoundary>
        <ExtensionPoint extensionPointId="menu" style={{ display: "flex", flexFlow: "column wrap" }} />
      </ErrorBoundary>
    </Box>
    {isMobile &&
      <Box marginTop={4}>
        <ButtonMasterUI fullWidth={true} onClick={logout} variant="secondary" text={{ en: "Logout" }} size="large" />
        <Box marginTop={8} textAlign="center">
          <a href="https://thinktum.ai"><img src="/images/menu-footer.png" alt={"liz, powered by thinktum"} title={"liz, powered by thinktum"} style={{ maxWidth: "100%" }} /></a>
        </Box>
      </Box>
    }
  </Box>
}

export default function DimmplMenu() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  if (isMobile) {
    return <Menu menuIcon={MenuIcon} mode="fullscreen" openFrom="right">
      <MenuContents isMobile={isMobile} />
    </Menu>
  } else {
    return <Menu menuIcon={MenuIcon} mode="dropdown">
      <MenuContents isMobile={isMobile} />
    </Menu>;

  }
}
