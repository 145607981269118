import { Grid, Typography } from "@material-ui/core";
import { useAPIUtils, LoginContext, Endpoints } from "@thinktuminc/core-ui-shared-react";
import ErrorX from "../images/ErrorX.png";
import { commonStyles } from "../styles/CommonStyle";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { AppConfigContext } from "../Config";
import { BrowserLogger } from "@thinktuminc/browser-logging";
import { JWTDecode, JWTDecodeConfiguration } from "@thinktuminc/authentication";
import { LoadingIcon } from "@thinktuminc/react-components";

const logger = new BrowserLogger("AuthAcceptPage");

const ErrorMsg = "An unexpected error occurred.";

// eslint-disable-next-line no-empty-pattern
export function AuthAcceptPage({}) {
    const { APIGetUnauthorized} = useAPIUtils();
    const config = React.useContext(AppConfigContext.ReactContext);
    const [errorState, setErrorState] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const loginContext = React.useContext(LoginContext);
    const navigate = useNavigate();
    const commonClass = commonStyles();

    const jwtDecoder = React.useMemo(() => new JWTDecode(new JWTDecodeConfiguration({publicKey: ""})), [])
    
    const acceptUrlCall = React.useCallback(async () => {
        if(!config) return;
        try {
            const response = await APIGetUnauthorized(
                config.authorizeUrl + Endpoints.ACCEPT + window.location.search,
                {
                    headers: {
                    },
                }
            );

            if (response?.data?.status >= 200 && response?.data?.status < 300) {
                logger.info("Successfully logged in", loginContext);
                loginContext.setLogin({
                    ...loginContext,
                    login: {
                        state: "LOGGED_IN",
                        JWTToken: response.data.value,
                        Details: jwtDecoder.decodeSession( response.data.value )
                    },
                });

                navigate("/");
            } else {
                logger.error("Log in failed", loginContext, response);
                response === null || response.data === null ? 
                    setErrorMessage(ErrorMsg) : 
                    setErrorMessage(response.data.message)
                setErrorState(true);
            }
        } catch (error: any) {
            logger.error("Log in failed", loginContext, error);
            if (error?.response?.data !== undefined) {
                setErrorMessage(error.response.data.message);
                setErrorState(true);
            } else {
                setErrorMessage( error.message);
                setErrorState(true);
            }
        }
    }, [APIGetUnauthorized, config, jwtDecoder, loginContext, navigate])

    React.useEffect(() => {
        if(config && window.location.search){
            acceptUrlCall();
        }
    }, [acceptUrlCall, config]);

    return (
        <Grid style={{backgroundColor: "white"}}>
            { errorState === false ? 
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        className={commonClass.centreGrid}
                    > 
                        <LoadingIcon   />
                    </Grid> :
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    className={commonClass.centreGrid}
                >
                    <Grid 
                        container
                        alignItems="center"
                        justifyContent="center"
                        className={commonClass.errorGrid}
                    >
                        <Grid 
                            container 
                            justifyContent="center" 
                            className={commonClass.errorImgGrid}
                        >
                            <img alt="Error X" width="30px" src={ErrorX}></img>
                        </Grid>
                        <Typography 
                            className={commonClass.errorText}
                        > 
                            Error: {errorMessage}
                        </Typography>
                    </Grid> 
                </Grid>
            }
        </Grid>
    );
}