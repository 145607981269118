import { LoginContext, useAPIUtils } from "@thinktuminc/core-ui-shared-react";
import { useNavigate } from "react-router-dom";

import * as React from "react";
import { useEffect, useState } from "react";

import "./Home.css";
import Layout from "./Layout";
import { AppConfigContext } from "../Config";
import { BrowserLogger } from "@thinktuminc/browser-logging";
import { Box } from "@material-ui/core";
import { LoadingIcon } from "@thinktuminc/react-components";

const returnUrlSessionStorageKey = "tt_return_url";
const logger = new BrowserLogger("HomePage");

export function AppContainer() {
    const navigate = useNavigate();

    const loginContext = React.useContext(LoginContext);
    const config = React.useContext(AppConfigContext.ReactContext);
    const [state, setState] = useState<"initial"|"loading"|"loaded">("initial");
    const [assets, setAssets] = useState<{js:string[], css:string[]}>({"js":[],"css":[]})
    const [newAssets, setNewAssets] = useState<{js:string[], css:string[]}>({"js":[],"css":[]})
    const [loadedAssets, setLoadedAssets] = useState<{js:string[], css:string[]}>({"js":[],"css":[]})
    const apiUtils = useAPIUtils();
    const returnTo = window.sessionStorage.getItem(returnUrlSessionStorageKey);

    const handleItemLoaded = React.useCallback((type:keyof typeof loadedAssets, file:string) => {
        setLoadedAssets(loadedAssets => ({
            js: type === "js" ? [...loadedAssets.js, file] : loadedAssets.js,
            css: type === "css" ? [...loadedAssets.css, file] : loadedAssets.css,
        }))
    }, [])

    useEffect(() => {
        if(loginContext.login.state === "UNKNOWN"){
            logger.debug("Unknown login state, switching to auth",  window.location.pathname, loginContext);
            window.sessionStorage.setItem(returnUrlSessionStorageKey, window.location.pathname);
            loginContext.setLogin({
                ...loginContext,
                login: { ...loginContext.login, state: "LOGGING_IN"}
            })
            navigate("/auth");
        }else if(loginContext.login.state === "LOGGED_IN"){
            if(returnTo){
                window.sessionStorage.removeItem(returnUrlSessionStorageKey);
                navigate(returnTo);
            }
            logger.debug("User is logged in, loading assets")
            if(assets.js.length + assets.css.length === 0){
                (async () => {
                    if(loginContext.login.state !== "LOGGED_IN"){
                        throw new Error("Should have been logged in, but apparently is not.");
                    }
                
                    try{
                        const assets = await apiUtils.get<{ fileName: string, mimeType:string, module:string }[]>(`${config?.moduleClientUrl}/assets`, {});
                        logger.debug("Found assets:", assets);
                        setNewAssets(assets.reduce((typeMap, asset) => {
                            switch(asset.mimeType){
                                case "text/css":
                                    typeMap.css.push(`${config?.moduleClientUrl}/assets/${asset.module}/${asset.fileName}`)
                                    break;
                                case "text/javascript":
                                case "application/javascript":
                                    typeMap.js.push(`${config?.moduleClientUrl}/assets/${asset.module}/${asset.fileName}`)
                                    break;
                            }
                            return typeMap;
                        }, {js:[], css:[]} as {js:string[], css:string[]}));
                       
                    }catch(e){
                        console.warn("Failed to load modules");
                    }
                })();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginContext, config])

    const progress = React.useMemo(() => {
        switch(state){
            case "loading":
                return (loadedAssets.js.length + loadedAssets.css.length) / ( assets.js.length + assets.css.length );
            case "initial":
                return 0;
            case "loaded":
                return 1;
        }
    }, [state, loadedAssets, assets])

    React.useEffect(() => {
        if(state !== "initial" && progress>=1){
            setState("loaded");
        }
    }, [state, progress])
    useEffect(() => {
        logger.warn("Assets incoming");
        setState(newAssets.js.length > 0 || newAssets.css.length > 0 ? "loading" : "initial");

        const cssAssetFilesToAdd = newAssets.css
            .filter(newCssFile => !assets.css.includes(newCssFile))
        const cssAssetElemsToAdd = cssAssetFilesToAdd
            .map(cssFile => {
                logger.info(`New css: ${cssFile}`);
                const elem = document.createElement("link");
                elem.setAttribute("href",cssFile);
                elem.setAttribute("rel","stylesheet");
                elem.addEventListener("load", () => { handleItemLoaded("css",cssFile);})
                return elem;
            });
        
        const jsAssetFilesToAdd = newAssets.js
            .filter(newJsFile => !assets.js.includes(newJsFile));
        const jsAssetElemsToAdd = jsAssetFilesToAdd
            .map(jsFile => {
                logger.info(`New js: ${jsFile}`);
                const elem = document.createElement("script");
                elem.setAttribute("src",jsFile);
                elem.setAttribute("async","true");
                elem.setAttribute("defer","true");
                elem.addEventListener("load", () => { handleItemLoaded("js", jsFile);})
                elem.addEventListener("error", () => { handleItemLoaded("js", jsFile);})
                return elem;
            })

        const allAssetsToAdd = [ ...cssAssetElemsToAdd, ...jsAssetElemsToAdd ];
        allAssetsToAdd.forEach(elem => {
            logger.info(`Adding`, elem);
            document.body.appendChild(elem)
        });

        setAssets({ js: [ ...assets.js, ...jsAssetFilesToAdd ], css: [ ...assets.css, ...cssAssetFilesToAdd ] });

        const cssAssetFilesToRemove = assets.css
            .filter(newCssFile => !newAssets.css.includes(newCssFile))
        const jsAssetFilesToRemove = assets.js
            .filter(newJsFile => !newAssets.js.includes(newJsFile));

        if(cssAssetFilesToRemove.length + jsAssetFilesToRemove.length > 0){
            logger.warn("There are assets that should be removed", [ ...cssAssetFilesToRemove, ...jsAssetFilesToRemove ])
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newAssets])

    const isLoading = state === "initial" || state === "loading"
    return (
        <>
            <Layout />
            <Box position="absolute" top={0} left={0} width="100vw" height="100vh" boxSizing="border-box" 
                bgcolor={isLoading?"rgba(255,255,255,0.5)" :"rgba(255,255,255,0.0)"}
                style={{
                    backdropFilter:isLoading ? "blur(3px)" : "blur(0px)",
                    pointerEvents:isLoading ? "all" : "none",
                    transition: "backdrop-filter 0.7s, background-color 0.7s"
                }
            } >
                <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center" style={{
                    opacity: isLoading?1:0,
                    transition: "opacity 1s",
                    pointerEvents: "none"
                }}>
                    <LoadingIcon progress={ progress } />
                </Box>
            </Box>
        </>
    )
}