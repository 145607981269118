import { fromMetaTag } from "@thinktuminc/browser-configuration";
import { ConfigurationContext } from '@thinktuminc/core-ui-shared-react';

class AppConfig {
    constructor(values: Partial<AppConfig>){
        Object.assign(this, values);
    }
    public apiUrl!: string;
    public authorizeUrl!:string;
    public moduleClientUrl!:string;
}

export const AppConfigContext = new ConfigurationContext(AppConfig, {
    "apiUrl": {
        retriever: fromMetaTag("api-location"),
        ignorePattern: /\$\{.*?\}/,
        default: "http://localhost:3000"
    },
    "authorizeUrl": {
        retriever: fromMetaTag("authorize-url"),
        ignorePattern: /\$\{.*?\}/,
        default: "http://localhost:3002/auth/"
    },
    "moduleClientUrl": {
        retriever: fromMetaTag("module-client-url"),
        ignorePattern: /\$\{.*?\}/,
        default: "http://localhost:8082"
    },
} );
