import { Grid, Box, useTheme, useMediaQuery } from "@material-ui/core";
import UserMenu from "../../components/UserMenu";
import Menu from "../menu/Menu";
import { Link } from "react-router-dom";
import { ExtensionPoint } from "@thinktuminc/core-ui-shared-react";
import { ErrorBoundary } from "@thinktuminc/react-components";
import { AlternateErrorContent } from "../ErrorContent";

const logo = "/images/liz-logo.png";

export function Header(){
    const theme = useTheme();
    const isMobile = useMediaQuery( theme.breakpoints.down( "xs" ) );
    
    return <>{ isMobile ? 
        <Box id="header" width="100vw" height="60px" boxSizing="border-box" bgcolor="#FFF" display='flex' borderBottom={`solid 1px #E8E8E8`} paddingX={5}>
            <Grid container justifyContent="space-between" alignContent="center" alignItems="center">
                <Grid item>
                    <Link to="/"><img src={logo} alt="liz" title="liz" style={{maxHeight:"21px", maxWidth:"24px", top:"19.5px", position:"absolute"}}/></Link>
                </Grid>
                <Grid item style={{marginRight:"-4px"}}>
                        <Menu />
                </Grid>
            </Grid>
        </Box> :
        <Box id="header" paddingRight={7} height="80px" boxSizing="border-box" bgcolor="#FFF" display='flex' borderBottom={`solid 1px #E8E8E8`}>
            <Grid container justifyContent="space-between" alignContent="center" alignItems="center">
                <Grid item xs={2}>
                    <Link to="/"><img src={logo} alt="liz" title="liz" style={{maxHeight:"29px", maxWidth:"34px", top:"24px", left:"60px", position:"absolute"}} /></Link>
                </Grid>
                <Grid item xs={7}>
                    <Box paddingLeft={1} alignItems="center">
                        <Menu />
                    </Box>
                </Grid>
                <Grid item xs={3} style={{display: 'flex', justifyContent: 'flex-end', height: '100%'}}>
                    <ErrorBoundary content={<AlternateErrorContent />}>
                        <ExtensionPoint extensionPointId="header-extra" style={{width: 'fit-content'}} />
                    </ErrorBoundary>
                    <UserMenu />
                </Grid>
            </Grid>
        </Box>
    }</>
}
