import React from "react";
import { ThemeProvider } from "@material-ui/core";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LoginPage } from "./pre-auth/LoginPage";
import { AuthAcceptPage } from "./pre-auth/AuthAcceptPage";
import { AppContainer } from "./post-auth/AppContainer";
import { LoginContextProvider } from "@thinktuminc/core-ui-shared-react";
import { AppConfigContext } from "./Config";
import { APIConfigContext } from "@thinktuminc/core-ui-shared-react";
import { MenuContextProvider, lizTheme } from "@thinktuminc/react-components";
import { QueryClientProvider, QueryClient } from "react-query";
import "./App.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/800.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/800.css";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppConfigContext.Provider>
        <MenuContextProvider>
          <APIConfigContext.Provider>
            <Router>
              <ThemeProvider theme={lizTheme}>
                <LoginContextProvider>
                  <Routes>
                    <Route path="/auth" element={<LoginPage />} />
                    <Route path="/auth/accept" element={<AuthAcceptPage />} />
                    <Route path="*" element={<AppContainer />} />
                  </Routes>
                </LoginContextProvider>
              </ThemeProvider>
            </Router>
          </APIConfigContext.Provider>
        </MenuContextProvider>
      </AppConfigContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
