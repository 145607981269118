import { makeStyles } from "@material-ui/core";

export const commonStyles = makeStyles({
    centreGrid: {
        minHeight: '90vh',
    },
    errorGrid: {
        width: '300px',
        backgroundColor: "#f6cdd1",
    },
    errorImgGrid: {
        width: "100%", 
        paddingTop: "20px",
    },
    errorText: {
        color: "#992730", 
        margin: "20px",
    },
});