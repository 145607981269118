import React from "react";
import { Button, ButtonMasterUI, MUIMenu } from "@thinktuminc/react-components";
import { LoginContext, Endpoints } from "@thinktuminc/core-ui-shared-react";
import UserIcon from "@material-ui/icons/Person";
import { AppConfigContext } from "../Config";
import { NavigationButton } from "@thinktuminc/react-components";
import { I18nText } from "@thinktuminc/i18n";
import { Box, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    padding: 0,
    marginTop: theme.spacing(8.25),
    marginBottom: theme.spacing(3.75),
    minWidth: 'unset',
    [theme.breakpoints.down("xs")]: { 
      minWidth: "0" 
    },
    "& .MuiButton-iconSizeMedium": {
      "& svg": {
        fontSize: 32,
        color: theme.palette.neutral[500]
      }
    },
    "&:hover": {
      backgroundColor: "#FFFFFF",
      color: theme.palette.neutral[900],
      "& .MuiButton-iconSizeMedium": {
        "& svg": {
          color: theme.palette.neutral[900]
        }
      }
    },
    "& .MuiButton-startIcon": {
      margin: 0
    }
  },
}));

export default function UserMenu() {
  const loginContext = React.useContext(LoginContext);
  const config = React.useContext(AppConfigContext.ReactContext);
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const classes = useStyles();
  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = async () => {
    loginContext.setLogin({
      ...loginContext,
      login: {
        state: "LOGGING_OUT",
      },
    });
    if (!config) {
      throw new Error("Logout but authorize not configured");
    }
    window.location.assign(config.authorizeUrl + Endpoints.ENDSESSION);
  };

  if (loginContext.login.state === "LOGGED_IN") {
    return (
      <div style={{ float: "right", display: 'flex', alignItems: 'center' }}>
        <Button
          aria-controls="user-menu"
          aria-haspopup="true"
          className={classes.button}
          onClick={handleClick}
          startIcon={<UserIcon />}
        />
        <MUIMenu
          id="user-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Box padding={4}>
            <Box
              maxWidth="20em"
              overflow={"hidden"}
              textOverflow="ellipsis"
              paddingBottom={4}
            >
              <Typography
                variant="h4"
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {loginContext.login.Details.name}
              </Typography>
            </Box>

            <ButtonMasterUI
              fullWidth={true}
              variant="secondary"
              size="large"
              onClick={logout}
              text={{ en: "Logout" }}
            />
          </Box>
        </MUIMenu>
      </div>
    );
  } else {
    return (
      <div style={{ float: "right" }}>
        <NavigationButton path="/auth" text={new I18nText({ en: "Login" })} />
      </div>
    );
  }
}
